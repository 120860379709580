<template>
	<div class="ranking-box">
		<div class="flex alcenter space">
			<div class="flex alcenter">
				<i class="iconfont icondata08 ft32" style="color:#FE8340;"></i>
				<span class="ml15 ft18 ftw600 cl-black">产品销量排行榜</span>
			</div>
			<div>
				<a-radio-group v-model="search.time_type" @change="changeType" button-style="solid">
					<a-radio-button :value="1">
						本月
					</a-radio-button>
					<a-radio-button :value="2">
						本年度
					</a-radio-button>
				</a-radio-group>
			</div>
		</div>

		<div class="mt24 wxb-table-gray">
			<a-table rowKey="rank" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				:loading="loading">
				<div class="flex alcenter center" slot="rank" slot-scope="rank,record">
					<img v-if="rank == 1" style="width: 16px; height: 26px;"
						src="../../../../assets/image/woyaoyou_num01@3x.png" />
					<img v-if="rank == 2" style="width: 16px; height: 26px;"
						src="../../../../assets/image/woyaoyou_num02@3x.png" />
					<img v-if="rank == 3" style="width: 16px; height: 26px;"
						src="../../../../assets/image/woyaoyou_num03@3x.png" />
					<span v-if="rank > 3">{{rank}}</span>
				</div>
				
				<div class="flex alcenter" slot="goods" slot-scope="goods,record">
					<img :src="goods.cover_img" class="product-cover_img"/>
					<div class="ml10 " style="text-align: left;" >{{goods.name}}</div>
				</div>

			</a-table>
		</div>
	</div>
</template>


<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		data() {
			return {
				loading: false,
				search:{
					time_type:1,
				},
				pagination:{
					total:0,
					current:1,
					pageSize:5,
				},
				columns: [{
						title: '排名',
						dataIndex: 'rank',
						scopedSlots: {
							customRender: 'rank'
						},
						align: 'center'
					},
					{
						title: '产品',
						dataIndex: 'goods',
						align: 'center',
						width:400,
						scopedSlots: {
							customRender: 'goods'
						}
					},
					{
						title: '销量',
						dataIndex: 'take_count',
						align: 'center',
						ellipsis: true
					},
				],
				datas:[],
			}
		},

		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMallStatisticsTakeCountRanking',{
					time_type:this.search.time_type,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			changeType() {
				this.pagination.current=1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.product-cover_img{
		width: 60px;
		height: 60px;
	}
</style>
