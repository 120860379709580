<template>
	<div>
		<div class="flex">
			<div style="width: 100%;">
				<a-row :gutter="30">
					<a-col :span="12">
						<div class="count-box">
							<div class="flex alcenter">
								<i class="iconfont icondata06 ft32" style="color:#00C3A0;"></i>
								<span class="ft18 cl-main ftw600 ml15">今日订单</span>
							</div>
							<div class="mt20 flex">
								<div class=" col2 count-box-item  flex alcenter center">
									<div class="text-center" >
										<div class="ft18 ftw600 cl-black">{{datas.today_order_num}}</div>
										<div class="mt16 ft12 cl-notice">订单数</div>
									</div>
								</div>
								<div class=" col2 count-box-item ml30 flex alcenter center">
									<div class="text-center" >
										<div class="ft18 ftw600 cl-black">¥{{datas.today_order_money}}</div>
										<div class="mt16 ft12 cl-notice">订单金额</div>
									</div>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="count-box">
							<div class="flex alcenter">
								<i class="iconfont icondata07 ft32" style="color:#33A0FD;"></i>
								<span class="ft18 cl-main ftw600 ml15">累计订单</span>
							</div>
							<div class="mt20 flex">
								<div class=" col2 count-box-item  flex alcenter center">
									<div class="text-center" >
										<div class="ft18 ftw600 cl-black">{{datas.total_order_num}}</div>
										<div class="mt16 ft12 cl-notice">订单数</div>
									</div>
								</div>
								<div class=" col2 count-box-item ml30 flex alcenter center">
									<div class="text-center" >
										<div class="ft18 ftw600 cl-black">¥{{datas.total_order_money}}</div>
										<div class="mt16 ft12 cl-notice">订单金额</div>
									</div>
								</div>
							</div>
						</div>
					</a-col>
					
				</a-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				datas:{
					today_order_num:0,
					today_order_money:0,
					total_order_num:0,
					total_order_money:0,
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				this.$http.api('admin/getMallStatisticsCount').then(res=>{
					this.datas=res.datas;
					console.log(res);
				}).catch(res=>{
					console.log(res);
				})
			}
		}
	}
</script>

<style>
	.count-box {
		width: 100%;
		height: 250px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 24px;
	}

	.count-box div {
		line-height: 1;
	}
	
	.count-box-item{
		height: 126px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
	}
</style>
