<template>
	<div class="mall-statistic-page" >
		<div class="ft20 cl-black cl-main ftw500">数据统计</div>
		
		<div class="mt20">
			<div >
				<div>
					<mall-statistic-count></mall-statistic-count>
				</div>
				<div class="mt20">
					<mall-statistic-trend></mall-statistic-trend>
				</div>
				<div class="mt20">
					<mall-statistic-ranking></mall-statistic-ranking>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import mallStatisticCount from './components/Statistics/Count.vue';
	import mallStatisticRanking from './components/Statistics/Ranking.vue';
	import mallStatisticTrend from './components/Statistics/Trend.vue';
	export default {
		components: {
			mallStatisticCount,
			mallStatisticRanking,
			mallStatisticTrend
		},
		data() {
			return {
				loading:false,
				
			}
		},
	}
</script>

<style>
	.mall-statistic-page{
		min-width: 1280px;
	}
</style>
